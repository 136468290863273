@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../assets/Nunito-Regular.ttf) format("truetype");
}

html {
    overscroll-behavior: none;
}

body,
#root {
    height: 100dvh;
}

.ui {
    /* the green is the primary color; used to drive attention on the ui
     * element */
    --color-green: #027f48;
    /* we use multiple grays starting with tone1 which is the darkest up to the
     * lighest. */
    /* tone1 is used for the text color */
    --color-gray-tone1: #707070;
    /* tone2 is mainly used for the disabled state */
    --color-gray-tone2: #c6c6c6;
    /* tone3 is mainly used as a background */
    --color-gray-tone3: #ebebeb;
    --color-red: #c10000;
    /* the white color is mainly used as the text color when the background is
     * the primary color */
    --color-white: white;
    /* the balck color is mainly used for borders */
    --color-black: black;

    --color-text: var(--color-gray-tone1);

    --font-size-h1: 34px;
    --font-line-height-h1: 42px;
    --font-size-h2: 22px;
    --font-line-height-h2: 32px;
    --font-size-text: 22px;
    --font-line-height-text: 32px;
    --font-size-p: 16px;
    --font-line-height-p: 22px;
}

.ui {
    font-family: "Nunito";
    color: var(--color-text);
    font-size: var(--font-size-text);
    line-height: var(--font-line-height-text);

    overscroll-behavior-y: contain;
}

.ui {
    --top-toolbar: 92px;
    --bottom-toolbar: 92px;

    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: var(--top-toolbar) calc(100% - var(--top-toolbar) - var(--bottom-toolbar)) var(--bottom-toolbar);
    grid-template-areas:
        "top-toolbar"
        "work-area"
        "bottom-toolbar";

    background: var(--color-white);

    > .top-bar {
        grid-area: top-toolbar;
    }

    > .work-area {
        grid-area: work-area;
    }

    > .bottom-bar {
        grid-area: bottom-toolbar;
    }
}

.ui .top-bar,
.ui .bottom-bar {
    z-index: 1;

    --gap: 5px;
    @media (width >= 1440px) {
        --gap: 10px;
    }

    display: flex;
    gap: var(--gap);

    align-items: center;
    background: inherit;

    > .filler {
        flex: 1;
    }

    button {
        border: 0;
        color: inherit;
        background: inherit;

        display: flex;
        gap: var(--gap);
        align-items: center;
    }

    &.primary {
        background: var(--color-green);
        color: var(--color-white);
    }
}

.ui .top-bar {
    padding: 0 5% 0 0;
}

.ui .bottom-bar {
    padding: 0 5%;
}

.ui.select-photo {
    &.camera {
        background: var(--color-gray-tone3);
    }

    .bottom-bar {
        justify-content: space-between;

        svg {
            width: 30px;
            height: 30px;
        }
    }
    .work-area {
        display: flex;
        width: 200%;

        transition: margin 0.3s ease-in;
        &.edit {
            margin-left: -100%;
        }

        > .image-panel,
        > .edit-panel {
            padding: 5%;
            flex: 0 0 50%;
        }

        > .edit-panel {
            canvas {
                max-width: 100%;
                max-height: 100%;
            }
        }

        > .image-panel {
            display: flex;
            flex-direction: column;

            /** preview-select-media only contains an input[type=file] needed to open the file dialog **/
            > .preview-select-media {
                display: none;
            }

            > .intro-text {
                flex: 1;

                display: flex;
                flex-direction: column;
                justify-content: center;

                text-align: center;

                > h1 {
                    margin-top: 20px;
                }

                > p {
                    margin-bottom: 20px;
                }

                > img {
                    @media (orientation: landscape) {
                        width: 300px;
                        align-self: center;
                    }
                }
            }

            > .preview-camera {
                display: none;
                flex-direction: column;
                position: relative;
                justify-content: flex-start;
                align-items: center;

                &.active {
                    display: flex;
                }

                > video {
                    max-width: 100%;
                }
            }

            .edit-icon {
                margin-left: auto;
            }
        }

        .preview-selected-image {
            flex-direction: column;
            position: relative;
            justify-content: flex-start;
            align-items: center;

            /* this is the container for the preview; make sure it fill the
             * parent (which is positionet by the root grid) and never
             * overflow*/
            width: 100%;
            height: 100%;

            canvas {
                border: 1px solid red;
                max-width: 100%;
                max-height: 100%;
            }

            > img {
                /* this is the img with the final preview; change it to fill
                 * the whole parent an let the object-fit property properly
                 * scale the image inside it. */
                width: 100%;
                height: 100%;
                object-position: top;
                object-fit: contain;
            }

            > .spinner {
                display: none;

                position: absolute;
                top: calc(50% - 40px);
                left: calc(50% - 40px);
            }

            &.processing {
                filter: grayscale(100%);
                opacity: 0.7;

                > .spinner {
                    display: block;
                }
            }

            > .warning {
                margin-top: 10px;
                color: var(--color-red);
                text-align: center;
            }
        }
    }
}

.ui.preview-model {
    .work-area {
        position: relative;

        > .view3d {
            height: 100%;
        }

        > .controls {
            --toggler-width: 100px;
            --toggler-padding: 5px;
            --arrow-height: 20px;
            --params-height: 350px;

            --top-position: calc(100% - var(--arrow-height) - var(--toggler-padding));
            --arrow-rotation: -90deg;
            &.open {
                --top-position: calc(100% - var(--arrow-height) - var(--toggler-padding) - var(--params-height));
                --arrow-rotation: 90deg;
            }

            position: absolute;

            width: 100%;
            top: var(--top-position);
            transition: top 0.3s ease-in;

            > .toggler {
                position: relative;
                width: var(--toggler-width);
                height: calc(2 * var(--arrow-height) + 2 * var(--toggler-padding));
                padding: var(--toggler-padding);

                display: flex;
                justify-content: center;
                align-items: flex-start;

                background: var(--color-green);
                border-radius: calc(var(--arrow-height) - var(--toggler-padding));
                margin: 0 auto;

                > svg {
                    transform: rotate(var(--arrow-rotation));
                    width: var(--arrow-height);
                    height: var(--arrow-height);

                    stroke: transparent;
                    fill: var(--color-white);
                }
            }

            > .texture-parameters {
                position: relative;
                top: calc(-1 * (var(--arrow-height) + var(--toggler-padding)));
                width: 100%;
                height: var(--params-height);

                background: var(--color-gray-tone3);
                border-top: 3px solid var(--color-green);
                padding: 7%;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                gap: 30px;

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    &.inline {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                > div.rotation {
                    label {
                        display: flex;
                        gap: 10px;

                        > span:first-child {
                            flex-grow: 1;
                        }

                        svg {
                            fill: var(--color-gray-tone1);
                        }
                    }
                }
            }
        }

        #debug_texture {
            position: absolute;
            bottom: 20px;
            max-width: 70%;
        }
    }
}

.ui.login {
    padding: 20%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ui.final-words {
    .work-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
}

.ui.account {
    .work-area {
        padding: 20% 5%;
        display: flex;
        flex-direction: column;

        .app-info,
        .queue-size {
            margin-top: 10px;
        }

        .app-info {
            display: flex;
            align-items: center;
            gap: 5px;

            > span.online {
                background: var(--color-green);
            }

            > span.offline {
                background: var(--color-red);
            }
        }
    }
}

.ui.customer-form {
    .top-bar button,
    .bottom-bar button {
        margin-left: auto;
    }

    .work-area {
        position: relative;
        padding: 7%;

        > form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            > div {
                display: flex;
                flex-direction: column;
                gap: 10px;

                > input,
                > button {
                    padding: 15px;
                }

                > input {
                    border: 1px solid var(--color-gray-tone2);

                    &::placeholder {
                        color: var(--color-gray-tone2);
                    }
                }

                > button {
                    margin-top: 40px;
                    border: transparent;
                    background: var(--color-green);
                    color: var(--color-white);

                    &.disabled {
                        background: var(--color-gray-tone2);
                    }
                }
            }
        }
    }
}

.ui h1 {
    font-size: var(--font-size-h1);
    line-height: var(--font-line-height-h1);
    font-weight: normal;
}

.ui h2 {
    font-size: var(--font-size-h2);
    line-height: var(--font-line-height-h2);
    font-weight: normal;
}

.ui p {
    font-size: var(--font-size-p);
    line-height: var(--font-line-height-p);
}

.ui .circle {
    display: inline-block;
    border-radius: 50px;
    background: black;
    border: 1px solid transparent;
    width: 10px;
    height: 10px;
}

.ui a.green-link {
    text-decoration: none;
    background-color: var(--color-green);
    color: var(--color-white);
    padding: 15px;
}

.ui .direction {
    &.right {
        transform: rotate(0deg);
    }
    &.left {
        transform: rotate(180deg);
    }
    &.top {
        transform: rotate(-90deg);
    }
    &.bottom {
        transform: rotate(90deg);
    }
}

.ui .primary .direction {
    stroke: var(--color-white);
    fill: var(--color-white);
}

.ui .top-bar button .direction,
.ui .bottom-bar button .direction {
    display: flex;
    align-items: center;

    > svg {
        width: 30px;
        height: 30px;
        stroke: transparent;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

input[type="range"] {
    appearance: none;
    -webkit-appearance: none;

    background-color: var(--color-green);
    height: 2px;
    vertical-align: middle;
}
/* input[type="range"]::-moz-range-track { */
/*     -moz-appearance: none; */
/*     border-radius: 5px; */
/*     box-shadow: inset 0 0 5px #333; */
/*     background-color: #999; */
/*     height: 10px; */
/* } */

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    background-color: var(--color-white);
    height: 15px;
    width: 15px;
    border: 2px solid var(--color-green);
    border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    background-color: var(--color-white);
    height: 15px;
    width: 15px;
    border: 2px solid var(--color-green);
    border-radius: 50%;
}

.static-list {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    user-select: none;

    > div {
        background: var(--color-gray-tone2);

        &.selected {
            background: var(--color-green);
        }

        color: var(--color-white);
        width: 40px;
        padding: 5px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.static-list.background {
    flex-wrap: wrap;
    > div {
        width: 20px;
        height: 20px;

        &.selected {
            background: inherit;
            border: 1px solid var(--color-black);
        }
    }
}
